import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { NON_AUTHORIZED_PAGES } from '@/constants/pages'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'

const RootLayout = () => {
  const [menu, setMenu] = useState<boolean>(false)
  const location = useLocation()

  const isLogin = NON_AUTHORIZED_PAGES.includes(location.pathname)

  const toggleMenu = () => setMenu(!menu)

  return (
    <div className='flex flex-col h-screen'>
      <Header
        menu={menu}
        toggleMenu={toggleMenu}
        isLogin={isLogin}
      />

      <div className='flex flex-1 overflow-x-hidden'>
        {!isLogin && <Sidebar menu={menu} />}

        <main className='flex-1 h-full bg-light-100'>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default RootLayout
