import { Link, useLocation } from 'react-router-dom'
import { NON_AUTHORIZED_PAGES, PAGES_SLUGS } from '@/constants/pages'
import LogoIcon from '@/assets/icons/logo.svg'

const Logo = () => {
  const location = useLocation()

  const isLogoDisabled =
    NON_AUTHORIZED_PAGES.includes(location.pathname)
    ? location.pathname
    : PAGES_SLUGS.jobs

  return (
    <Link to={isLogoDisabled} className='flex-center-y gap-2 logo'>
      <LogoIcon />
      <span className='self-end text-white font-medium leading-[30px] max-md:hidden'>
          AVI
      </span>
    </Link>
  )
}

export default Logo
