import { Suspense, lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PAGES_SLUGS } from '@/constants/pages'
import { ErrorBoundary } from '@/components'

import App from '../App'
import ProtectedRoute from './ProtectedRoute'

const dynamicImport = (importFunction, retries = 3) => {
  return lazy(() =>
    new Promise((resolve, reject) => {
      const attempt = () => {
        importFunction()
          .then(resolve)
          .catch((error) => {
            if (retries === 0) {
              console.error('Failed to load module after multiple attempts:', error)
              reject(error)
            } else {
              setTimeout(() => {
                retries--
                attempt()
              }, 1000) // Retry after 1 second
            }
          })
      }
      attempt()
    })
  )
}

// Lazy loading components
const Dashboard = dynamicImport(() => import('@/pages/dashboard/Dashboard'))
const TrainingRequests = dynamicImport(() => import('@/pages/training-requests/TrainingRequests'))
const TrainingTypes = dynamicImport(() => import('@/pages/training-types/TrainingTypes'))
const DrugAlcohol = dynamicImport(() => import('@/pages/drug-alcohol/DrugAlcohol'))
const Schedule = dynamicImport(() => import('@/pages/schedule/Schedule'))
const ScheduleSingle = dynamicImport(() => import('@/pages/schedule-single/ScheduleSingle'))
const ScheduleVacationRequests = dynamicImport(() => import('@/pages/schedule-vacation/ScheduleVacation'))
const ScheduleStatistics = dynamicImport(() => import('@/pages/schedule-statistics/ScheduleStatistics'))
const Members = dynamicImport(() => import('@/pages/members/Members'))
const Member = dynamicImport(() => import('@/pages/member/Member'))
const NewMember = dynamicImport(() => import('@/pages/new-member/NewMember'))
const Jobs = dynamicImport(() => import('@/pages/jobs/Jobs'))
const Job = dynamicImport(() => import('@/pages/job/Job'))
const NewJob = dynamicImport(() => import('@/pages/new-job/NewJob'))
const Login = dynamicImport(() => import('@/pages/auth/login/Login'))
const ResetPassword = dynamicImport(() => import('@/pages/auth/reset-password/ResetPassword'))
const CreatePassword = dynamicImport(() => import('@/pages/auth/create-password/CreatePassword'))
const Aircrafts = dynamicImport(() => import('@/pages/aircrafts/Aircrafts'))
const Categories = dynamicImport(() => import('@/pages/categories/Categories'))
const Consumables = dynamicImport(() => import('@/pages/consumables/Consumables'))
const CalibratedTools = dynamicImport(() => import('@/pages/сalibrated-tools/CalibratedTools'))
const Customers = dynamicImport(() => import('@/pages/customers/Customers'))
const Customer = dynamicImport(() => import('@/pages/customer/Customer'))
const NewCustomer = dynamicImport(() => import('@/pages/new-customer/NewCustomer'))
const PdfTemplates = dynamicImport(() => import('@/pages/pdf-templates/PdfTemplates'))
const PdfRequests = dynamicImport(() => import('@/pages/pdf-requests/PdfRequests'))
const Settings = dynamicImport(() => import('@/pages/settings/Settings'))
const AccessDenied = dynamicImport(() => import('@/pages/error/AccessDenied'))
const NotFound = dynamicImport(() => import('@/pages/error/NotFound'))

const Loading = () => <div>Loading...</div>

const SuspenseWrapper = ({ children }) => <Suspense fallback={<Loading />}>{children}</Suspense>


export const router = createBrowserRouter([
  {
    path: PAGES_SLUGS.home,
    element: <Navigate to={PAGES_SLUGS.jobs} replace />,
  },
  {
    path: PAGES_SLUGS.home,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: PAGES_SLUGS.dashboardId,
        element: <SuspenseWrapper><Dashboard /></SuspenseWrapper>,
      },

      // Members
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_HR', 'ROLE_QA', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.members, element: <SuspenseWrapper><Members /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_HR', 'ROLE_QA', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.memberId, element: <SuspenseWrapper><Member /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.addNewMember, element: <SuspenseWrapper><NewMember /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.editMemberId, element: <SuspenseWrapper><NewMember /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Jobs
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.jobs, element: <SuspenseWrapper><Jobs /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        path: PAGES_SLUGS.jobId,
        element: <SuspenseWrapper><Job /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.addNewJob, element: <SuspenseWrapper><NewJob /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.editJobId, element: <SuspenseWrapper><NewJob /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Calibrated-Tools
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.calibratedTools, element: <SuspenseWrapper><CalibratedTools /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Consumables
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.consumables, element: <SuspenseWrapper><Consumables /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Aircrafts
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.aircrafts, element: <SuspenseWrapper><Aircrafts /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Customers
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.customers, element: <SuspenseWrapper><Customers /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.customerId, element: <SuspenseWrapper><Customer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.addNewCustomer, element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.editCustomerId, element: <SuspenseWrapper><NewCustomer /></SuspenseWrapper> } ],
        errorElement: <ErrorBoundary />
      },

      // Categories
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_DOM', 'ROLE_QA']} />,
        children: [{ path: PAGES_SLUGS.categories, element: <SuspenseWrapper><Categories /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Trainings
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.trainingTypes, element: <SuspenseWrapper><TrainingTypes /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.trainingRequests, element: <SuspenseWrapper><TrainingRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // DrugAlcohol
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR']} />,
        children: [{ path: PAGES_SLUGS.drugAlcohol, element: <SuspenseWrapper><DrugAlcohol /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Schedule
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.schedule, element: <SuspenseWrapper><Schedule /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.scheduleVacationRequests, element: <SuspenseWrapper><ScheduleVacationRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.scheduleStatistics, element: <SuspenseWrapper><ScheduleStatistics /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.scheduleId, element: <SuspenseWrapper><ScheduleSingle /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // PDF Templates
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC']} />,
        children: [{ path: PAGES_SLUGS.pdfTemplates, element: <SuspenseWrapper><PdfTemplates /> </SuspenseWrapper>}],
        errorElement: <ErrorBoundary />
      },
      {
        element: <ProtectedRoute allowedRoles={['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM']} />,
        children: [{ path: PAGES_SLUGS.pdfRequests, element: <SuspenseWrapper><PdfRequests /></SuspenseWrapper> }],
        errorElement: <ErrorBoundary />
      },

      // Auth
      {
        path: PAGES_SLUGS.login,
        element: <SuspenseWrapper><Login /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.acceptInvite,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.createPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.resetPassword,
        element: <SuspenseWrapper><ResetPassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: PAGES_SLUGS.verifyPassword,
        element: <SuspenseWrapper><CreatePassword /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      {
        path: PAGES_SLUGS.settings,
        element: <SuspenseWrapper><Settings /></SuspenseWrapper>,
        errorElement: <ErrorBoundary />,
      },

      // Access Denied
      {
        path: PAGES_SLUGS.accessDenied,
        element: <SuspenseWrapper><AccessDenied /></SuspenseWrapper>,
      },

      // Not-Found
      {
        path: PAGES_SLUGS.notFound,
        element: <SuspenseWrapper><NotFound /></SuspenseWrapper>,
      },
    ]
  }
])
