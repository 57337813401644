import { MouseEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { PAGES_SLUGS } from '@/constants/pages'
import { useAuth } from '@/context/AuthContext'
import { useUser } from '@/context/UserProvider'
import './Sidebar.css'

const menuItems = [
  { name: 'Dashboard', path: PAGES_SLUGS.dashboard, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] }, // TODO:DONE
  { name: 'Jobs', path: PAGES_SLUGS.jobs, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'] }, // TODO:DONE
  { name: 'Members', path: PAGES_SLUGS.members, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] }, // TODO:DONE
  { name: 'Training Requests', path: PAGES_SLUGS.trainingRequests, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'] }, // TODO:DONE
  { name: 'Training Types', path: PAGES_SLUGS.trainingTypes, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR'] }, // TODO:DONE
  { name: 'D&A Program', path: PAGES_SLUGS.drugAlcohol, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR'] }, // TODO:DONE
  { name: 'Schedule', path: PAGES_SLUGS.schedule, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] }, // TODO:DONE
  { name: 'Consumables', path: PAGES_SLUGS.consumables, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'] }, // TODO:DONE
  { name: 'Calibrated Tools', path: PAGES_SLUGS.calibratedTools, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'] },// TODO:DONE
  { name: 'Aircrafts', path: PAGES_SLUGS.aircrafts, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'] }, // TODO:DONE
  { name: 'Job Categories', path: PAGES_SLUGS.categories, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM'] }, // TODO:DONE
  { name: 'Customers', path: PAGES_SLUGS.customers, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] }, // TODO:DONE
  { name: 'PDF Templates', path: PAGES_SLUGS.pdfTemplates, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] },
  { name: 'PDF Requests', path: PAGES_SLUGS.pdfRequests, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'] }  // TODO:DONE
]

const Sidebar = ({ menu }: { menu: boolean }) => {
  const { onLogout } = useAuth()
  const { role, user } = useUser()
  const location = useLocation()
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path)
  }

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault()
    onLogout()
  }

  return (
    <aside
      className={`sidebar absolute lg:relative h-full right-0 lg:block duration-300 ease-in-out text-white z-10 overflow-y-auto scrollbar-hide
        ${isTablet ? (menu ? 'block w-[350px] bg-primary' : 'hidden') : 'block bg-dark-default'} ${isMobile ? 'fixed z-[100]' : ''}`}
    >
      <nav className='h-full py-[40px] flex-column justify-between relative'>
        <div className='flex-column'>
          {menuItems.map(item => (
            (!item.roles || (role && item.roles.includes(role))) && (
              <Link
                key={item.name}
                to={item.name === 'Dashboard' ? `${PAGES_SLUGS.dashboard}/${user.id}` : item.path}
                className={`sidebar-link relative py-5 px-[40px] leading-[16px]
              ${(item.name === 'Consumables' || item.name === 'Customers') && 'mt-7'}
              ${isLinkActive(item.path) ? 'text-white font-bold' : 'text-gray-100 font-medium'} ${menu && 'text-white hover:bg-white hover:text-primary'} hover:bg-primary hover:text-white duration-300`}
              >
              <span
                className={`absolute left-0 top-0 bottom-0 h-full w-[6px] bg-primary rounded-r duration-300 
              ${isLinkActive(item.path) ? 'block' : 'hidden'}`}
              ></span>
                {item.name}
              </Link>
            )
          ))}
        </div>
        <Link
          key='logout'
          onClick={handleLogout}
          to=''
          className='sidebar-link text-gray-100 w-full py-5 px-[40px] leading-[16px] hover:bg-primary hover:text-white duration-300'
        >
          Logout
        </Link>
      </nav>
    </aside>
  )
}

export default Sidebar
