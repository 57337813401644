import { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { useAuth } from './AuthContext'
import { fetchGetUserMe } from '@/lib/members'

interface IUserContext {
  user: any
  role: string | null
  hasMainRole: boolean
  hasSecondaryRole: boolean
  isSuperAdmin: boolean
  isCrewChief: boolean
  isWorker: boolean
  isDOM: boolean
  isQA: boolean
  isHR: boolean
  isAuthenticated: boolean
}

const UserContext = createContext<IUserContext | undefined>(undefined)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null)
  const [role, setRole] = useState<string | null>(null)
  const { isAuth } = useAuth()

  useEffect(() => {
    const getUserData = async () => {
      if (isAuth) {
        try {
          const userData = await fetchGetUserMe()
          setUser(userData)
          const userRoles = userData?.roles || []
          // Відбираємо першу роль, яка не "ROLE_USER"
          const actualRole = userRoles.find((role: string) => role !== 'ROLE_USER')
          setRole(actualRole)
        } catch (error) {
          console.error('Failed to fetch user data:', error)
        }
      }
    }

    getUserData()
  }, [isAuth])

  const isSuperAdmin = role === 'ROLE_SA'
  const isCrewChief = role === 'ROLE_CC'
  const isWorker = role === 'ROLE_WORKER'
  const isDOM = role === 'ROLE_DOM'
  const isQA = role === 'ROLE_QA'
  const isHR = role === 'ROLE_HR'

  const hasMainRole = isSuperAdmin || isDOM || isQA
  const hasSecondaryRole = isSuperAdmin || isDOM || isQA || isWorker

  return (
    <UserContext.Provider
      value={{
        user,
        role,
        isQA,
        isHR,
        isDOM,
        isWorker,
        isCrewChief,
        isSuperAdmin,
        hasMainRole,
        hasSecondaryRole,
        isAuthenticated: isAuth
    }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
